import React, {useState, useEffect, useRef} from 'react'

//import {JSONTree} from 'react-json-tree'

import {
    JsonTree,
	 ADD_DELTA_TYPE,
     UPDATE_DELTA_TYPE
    } from 'react-editable-json-tree';
  import colors from '../colors.js'
  import {Document, Page, pdfjs} from "react-pdf"
  import {useCookies} from 'react-cookie'
  import forms from 'lazarus-forms'
  import '../index.css';
  import '../keyVals.css';

  
  // import PdfExample from '../PDFExample.json'
  
  // Components
  import FullLoadingScreen from './FullLoadingScreen'
 
  import CustomInput from './CustomInput.js'
  import ValueCard from './ValueCard.js'
  import KeyValue from './KeyValue.js';

  
  // Images
  import Left from '../images/reshot-icon-arrow-left.svg'
  import Right from '../images/reshot-icon-arrow-right.svg'
  
  import Download from '../images/file-upload-svgrepo-com.svg' 
  import Upload from '../images/upload-solid.svg'
  import FormsLogo from '../images/ccs-black.svg'

  import RotateRight from '../images/rotate-right.svg'
  import RotateLeft from '../images/rotate-left.svg'
  // Styles
  import '../styles/App.css'


  function Main() {
	  
	const inputRef = useRef()
    const [cookies, setCookie] = useCookies([])
    
    const [rotation,setRotation] = useState(0)
    const [urlParam, setUrlParam] = useState(process.env.REACT_APP_URL_PARAM || 'api')
    const [isLoading, setIsLoading] = useState(true)
    // handles render
    const [isAuthModal, setIsAuthModal] = useState(false)
    // handles animation
    const [isAuthModalShowing, setIsAuthModalShowing] = useState(false)
    // page number for pdfs
    const [pageNumber, setPageNumber] = useState(1)
    const [numPagesArr, setNumPagesArr] = useState([])
    const [selectedAsideHeaderTab, setSelectedAsideHeaderTab] = useState(null)
    const [selectedAsideSubHeaderTab, setSelectedAsideSubHeaderTab] = useState('keyValuePairs')
    const [selectedFileIndex, setSelectedFileIndex] = useState(0)
    const [jsonCopiedIndex, setJsonCopiedIndex] = useState(null)
    // responses from api with all bounding box data, etc.
    const [responses, setResponses] = useState([])
    // When zip file is uploaded, response is stored but not shown until button is clicked
    const [showingResponses, setShowingResponses] = useState([])
    // trigger a render by altering state, is never read
    const [renderTrigger, setRenderTrigger] = useState()
    /*
      I set the app size according to screen size on page load
      Responsiveness is tricky as changing the canvas size removed
      bounding boxes.
    */
    const [appSize, setAppSize] = useState(0)
    /* Message in the auth modal */
    const [message, setMessage] = useState('Please enter your credentials')
    /* Stores auth keys and all uploaded files */
	
	const [numPageRendered, setNumPageRendered] = useState(0)
	const [masterDivCollection, setMasterDivCollection] = useState([])
	
	const [currentCollection, setCurrentCollection] = useState([])
	const [keyValue2Collection, setKeyValue2Collection] = useState([])
	
	//const [colorMap, setColorMap] = useState({})
	const [colorCollection, setColorCollection] = useState([])
	
	const [documentName, setDocumentName] = useState([])
	
	const [preLoadedZipFile, setPreloadedZipFile] = useState()
	
	const preLoadedZipFileRef = useRef({})

	const preLoadedZipFileIndexRef = useRef()
	
	const [highlighted, setHighlighted] = useState(0)
	
	
	/* Prevent resizing canvas more than once */
    const [resizedCanvases, setResizedCanvases] = useState({})

    const [authForm, _setAuthForm] = useState({
      authKey: '',
      orgId: '',
      files: [],
    })
  
    const authFormRef = useRef({
      authKey: '',
      orgId: '',
      files: [],
    })
	
	function setPreLoadedZipFileRef(data) {
		preLoadedZipFileRef.current = data
		setPreloadedZipFile(Date.now())

	}
	
    function setAuthForm(data) {
      authFormRef.current = data
      _setAuthForm(data)
    }
    
    function readOnlyFuncion (keyName, data, keyPath, deep, dataType)  {
      if (keyName === 'data' || keyName === 'Patient'){
        return false
        }
      else{
        return true
      }
    }
    
      function rotate() {
        let newRotation = rotation + 90;
        if(newRotation >= 360){
          newRotation =- 360;
        }
        let doc = document.getElementsByClassName('visible')
        for (var i = 0; i < doc.length; i++) {
          if (doc[i].childNodes[0].className == "react-pdf__Page") {
            doc[i].childNodes[0].style.transform=`rotate(${newRotation}deg)`
            break;
          }
        }
        setRotation(newRotation);
      }
      function rotateleft(){
        let newRotation = rotation - 90;
        if(newRotation >= 360){
          newRotation =- 360;
        }
        let doc = document.getElementsByClassName('visible')
        for (var i = 0; i < doc.length; i++) {
          if (doc[i].childNodes[0].className == "react-pdf__Page") {
            doc[i].childNodes[0].style.transform=`rotate(${newRotation}deg)`
            break;
          }
        }
        setRotation(newRotation);
      }
    
 
  
    function onChangeForm(e) {
      setAuthForm({
        ...authForm,
        [e.target.name]: e.target.value,
      })
      setCookie(e.target.name, e.target.value, {path: '/'})
    }
  
   
    function onClickCopy() {
      navigator.permissions.query({name: 'clipboard-write'}).then((result) => {
        if (result.state === 'granted' || result.state === 'prompt') {
          /* write to the clipboard now */
          const copyText = JSON.stringify(responses[selectedFileIndex])
          navigator.clipboard.writeText(copyText).then(() => {
            /* clipboard successfully set */
            setJsonCopiedIndex(selectedFileIndex)
          }, function() {
            /* clipboard write failed */
            //console.log('failed to copy to clipboard')
            setJsonCopiedIndex(null)
          })
        }
      })
    }

	async function getHandle() {
		// set some options, like the suggested file name and the file type.
		
		var currentDocumentName = documentName[selectedFileIndex].toString()
		var suggestedFileNameRoot = currentDocumentName.replace(/[.]{1}[A-Za-z]*$/, '')
		var suggestedFileName = suggestedFileNameRoot.concat(".json")

		const options = {
			suggestedName: suggestedFileName,
			types: [
			{
				description: 'JSON Files',
				accept: {
					'text/json': ['.json'],
				},
			},
			],
		};

		// prompt the user for the location to save the file.
		const handle = await window.showSaveFilePicker(options);

		return handle
	}

	async function save(handle, text) {
		// creates a writable, used to write data to the file.
		const writable = await handle.createWritable();

		// write a string to the writable.
		await writable.write(text);

		// close the writable and save all changes to disk. this will prompt the user for write permission to the file, if it's the first time.
		await writable.close();
	}

    const saveUsingHandle = (() => {
		
	const  myData  = (responses[selectedFileIndex]);
	
	const json = JSON.stringify(myData, null, 2);
		
	 getHandle().then((h1) => {save(h1, json)});
	
	})
 
  
    function onClickSave() {
      const  myData  = (responses[selectedFileIndex]);
       // I am assuming that "this.state.myData"
      // is an object and I wrote it to file as
      // json
    
    // create file in browser
    const fileName = "UpdatedJson";
    const json = JSON.stringify(myData, null, 2);
    const blob = new Blob([json], { type: "application/json" });
    const href = URL.createObjectURL(blob);
    
    // create "a" HTML element with href to file
    const link = document.createElement("a");
    link.href = href;
    link.download = fileName + ".json";
    document.body.appendChild(link);
    link.click();
    
    // clean up "a" element & remove ObjectURL
    document.body.removeChild(link)
    ;
    URL.revokeObjectURL(href);
    }
	  
	  
    function b64toFile(b64, filename, mimeType){
      return (fetch(b64)
              .then(function(res){return res.arrayBuffer();})
              .then(function(buf){return new File([buf], filename,{type:mimeType});})
      );
    }
  
    	// function name is pluralized
	 function returnFileNamesOfTypes(filesObj) {
	
      var pairedFilesCollection = []
	  
      const keys = Object.keys(filesObj)
	  
      const filesOfTypePdf = keys.filter((key) => {
        return key.toLowerCase().includes('pdf')
      })

     const filesOfTypeJson = keys.filter((key) => {
        return key.toLowerCase().includes('json')
      })
	
	 
	   for (var i=0; i < filesOfTypePdf.length; i++) {
		 
		
		  
		var currentPdf = filesOfTypePdf[i]
		var fileNameOnlyPdf = currentPdf.replace(/^.*[\\/]/, '')
	    var fileNameRootPdf = fileNameOnlyPdf.replace(/[.]{1}[A-Za-z]*$/, '')
						
		for (var j=0; j < filesOfTypeJson.length; j++) {
		
         var currentJson = filesOfTypeJson[j]
		 var fileNameOnlyJson = currentJson.replace(/^.*[\\/]/, '')
	     var fileNameRootJson = fileNameOnlyJson.replace(/[.]{1}[A-Za-z]*$/, '') 	
		 if (fileNameRootPdf === fileNameRootJson) {
//console.log(currentPdf+ " is matched with " +currentJson)			 
			let temp1 = [currentPdf,currentJson]

		   pairedFilesCollection.push(temp1)		   
     	 }
    	}

	 }
	 
// the calling function will check for a length	 
     return pairedFilesCollection	
    }
  
  
    function preLoadZipFile(file) {
	
      var firstRunIdx
      var jsZip1 = require('../../node_modules/jszip/dist/jszip.js')
      var zipper1 = new jsZip1()
      zipper1.loadAsync(file)
          .then((zip) => {
			
		   var pdfJsonPairs = returnFileNamesOfTypes(zip.files)
		   var matchedPairsIndex = pdfJsonPairs.length
		   		   
console.log("preLoadZipFile: File is " +file)
console.log("preLoadZipFile: Matched Index is " +matchedPairsIndex)
         
         if (matchedPairsIndex > 0) {
		 firstRunIdx = parseInt(matchedPairsIndex) - 1	 
		   
		   var preLoadedZipFileObj = {
							zipfile: file,
							index: firstRunIdx,
							pairs: pdfJsonPairs
		   }
		   
//		   preLoadedZipFileRef.current = preLoadedZipFileObj
			setPreLoadedZipFileRef(preLoadedZipFileObj)
			
			loadZipFile(file,pdfJsonPairs,firstRunIdx)
		 } else {
			console.log("Matched pdf/json pairs is zero - check daya zip file...") 
		 }
		 
		  })
				zipper1 = null
    }

    function returnFileNameOfType(filesObj, type, idx) {
      const keys = Object.keys(filesObj)
      const filesOfType = keys.filter((key) => {
        return key.toLowerCase().includes(type)
      })
      let key
      if (filesOfType.length) {
        key = [filesOfType[idx]]
      }
      return key
    }
  
   
    function loadZipFile(file, pdfJsonPairs,index) {
console.log("loadZipeFile is being called with an index of " +index)
      setIsLoading(true)
      const jsZip = require('../../node_modules/jszip/dist/jszip.js')
      const zipper = new jsZip()
      zipper.loadAsync(file)
          .then((zip) => { 
 //           const pdfFileName = returnFileNameOfType(zip.files,'pdf', index)
 //           const jsonFileName = returnFileNameOfType(zip.files,'json', index)
 const pdfFileName = pdfJsonPairs[index][0]
 const jsonFileName = pdfJsonPairs[index][1]
            if (zip.files && pdfFileName && jsonFileName) {
              // To handle pdfs and other image types, first extract data.json to get file type
              // For images use .async('blob')
              zip.file(pdfFileName)
                  .async('base64')
                  .then((b64) => {
					var fileNamed = pdfFileName.replace(/^.*[\\/]/, '')
					var currentDocumentNames = [...documentName]
					currentDocumentNames.push(fileNamed)
					setDocumentName(currentDocumentNames)
                    const str = 'data:text/plain;base64,' + b64
                    b64toFile(str, fileNamed, 'application/pdf')
                        .then((file) => {
                          zip.file(jsonFileName)
                              .async('string')
                              .then((json) => JSON.parse(json))
                              .then((data2) => {
								const newKeyValuesObj = data2
								setCurrentCollection([])
								keyValue2(newKeyValuesObj,'root')
								assignColors()
								// sortCurrentCollection()
								const newCollections = [...keyValue2Collection,currentCollection]
								setKeyValue2Collection(newCollections)
								
                                setSelectedAsideHeaderTab('values')
                                const newFiles = [
                                  ...authForm.files, [file],
                                ]
                                const newFileIndex = newFiles.length - 1
                                const newResponses = [...responses]
                                newResponses[newFileIndex] = data2
                                setSelectedFileIndex(newFileIndex)
                                setResponses(newResponses)
                                const newShowingResponses = [...showingResponses]
                                newShowingResponses[newFileIndex] = null
                                setShowingResponses(newShowingResponses)
                                setAuthForm({
                                  ...authForm,
                                  files: newFiles,
                                  test: 'test',
                                })
                                return data2
                              })
                              .then((data2) => {
                                resizeCanvas()
                                return data2
                              })
                              .catch((error) => {
                                alert('Error: Unrecognized json file')
                                console.log('error getting json', error)
                              })
                        })
                  })
                  .catch((error) => {
                    alert('Error: Make sure your zip file contains data.pdf and data.json')
                    console.log('error getting file', error)
                  })
            } else {
              alert('Please make sure your zip file contains a data.pdf and data.json')
            }
          })

    }
  
    function onChangeFile(e) {
      if (e.target.files.length) {
        const file = e.target.files[0]
        const fileType = file.type
        if (fileType.split('/')[0] === 'image' || fileType === 'application/pdf') {
          const newFiles = [
            ...authForm.files, e.target.files
          ]
          const newFileIndex = newFiles.length - 1
          const newResponses = [...responses]
          newResponses[newFileIndex] = null
          setSelectedFileIndex(newFileIndex)
          setResponses(newResponses)
          const newShowingResponses = [...showingResponses]
          newShowingResponses[newFileIndex] = null
          setShowingResponses(newShowingResponses)
          setAuthForm({
            ...authForm,
            files: newFiles,
          })
        } else if (
            fileType === 'application/zip' ||
            fileType === 'application/zip-compressed' ||
            fileType === 'application/x-zip-compressed'
        ) { // has json and pdf
//          loadZipFile(file)
			preLoadZipFile(file)
        } else {
          alert('Choose zip or image file')
        }
      }
    }
  
    function onClickUploadButton() {
      // Clicking the upload image, triggers the click of the actual input
      inputRef.current.click()
    }
  
    function onDocumentLoadSuccess({numPages}) {
console.log("Selected File Index passed into onDocumentLoadSuccess is " +selectedFileIndex)		
console.log("Number of Pages passed into onDocumentLoadSuccess is " +numPages)

      var newNumPagesArr = [...numPagesArr] 

      newNumPagesArr[selectedFileIndex] = numPages 
      setNumPagesArr(newNumPagesArr) // [5]
      setRenderTrigger(Date.now())
      // pdf sometimes isn't fully rendered despite this callback being called
      setTimeout(() => {
        setIsLoading(false)
      }, 2000)
          onClickUploadFile()
  
    }
  
    function onChangeJSON(e, category, index) {
      const currentResponse = responses[selectedFileIndex]
      if (category === 'entity') {
        currentResponse.entitiesExtracted[index].editedContent =
            e.target.value
      } else if (category === 'key') {
        currentResponse.keyValuePairs[index].key.editedContent =
            e.target.value
      } else if (category === 'value') {
        currentResponse.keyValuePairs[index].value.editedContent =
            e.target.value
      } else {
        alert('Edit error')
      }
      const newResponses = responses
      newResponses[selectedFileIndex] = currentResponse
      setResponses(newResponses)
    }
  
    function onClickUploadFile() {
      const isFile = authFormRef.current.files[selectedFileIndex]
      if (isFile) {
        setIsLoading(true)
        // [0][0] for image, [0] for pdf
        const file = authFormRef.current.files[selectedFileIndex][0] || authFormRef.current.files[selectedFileIndex]
        if (responses[selectedFileIndex]) { // for pdf
          const newShowingResponses = [...showingResponses]
          newShowingResponses[selectedFileIndex] = responses[selectedFileIndex]
          setShowingResponses(newShowingResponses)
          setIsLoading(false)
 
        } else {
          const fileName = 'file-' + Date.now()
          const fileType = file.type
          const blob = new Blob([file], {type: fileType})
  
          const formdata = new FormData()
          formdata.append('file', blob, fileName)
  
          setIsLoading(false)
          setSelectedAsideHeaderTab('values')
        }
 

      } else {
        alert('Please attach a file')
      }
    }
  
   function returnNewColor(i) {
      // Returns index for color
      if (i >= colors.length) {
        return i % colors.length
      } else {
        return i
      }
    }
	
    function resizeCanvas() {
      // change canvas size to match size of rendered image
      const images = [
        {
          type: 'keyValuePairs',
          element: document.getElementById('rendered-image-keyValuePairs')
        },
        {
          type: 'entities',
          element: document.getElementById('rendered-image-entities')
        },
      ]
      if (images[0].element && images[1].element) {
        images.forEach((image) => {
          // first image w/h is used because it is visible and has w/h
          const height = images[0].element.clientHeight
          const width = images[0].element.clientWidth
          // console.log('height and width', height, width)
          const id = 'image-canvas-' + image.type + '-' + selectedFileIndex
          const c = document.getElementById(id)
          const ctx = c ? c.getContext('2d') : null
          if (height && width && ctx && !resizedCanvases[selectedFileIndex]) {
            c.style.width = width
            c.style.height = height
            ctx.canvas.width = width
            ctx.canvas.height = height
            setResizedCanvases({
              ...resizedCanvases,
              [selectedFileIndex]: true
            })
          }
        })
      }
    }
  
 
 function assignColors() {
	 
	var colorMap = {}
	
	for (var i=0; i < currentCollection.length; i++) {
	
	var currentSubArry = currentCollection[i]
	var positionId = currentSubArry[4]
//	console.log("Position Id is " +positionId)
	colorMap[positionId] = getColor(i)
	
    }		
	 
	colorCollection.push(colorMap)
//console.log(colorMap)	
 }	 

 function sortCurrentCollection() {
	var tempSorted = []
    var breakIndex = currentCollection.length
//console.log("BreakIndex is set to " +breakIndex)
var indexesToRemove = []
    while(breakIndex > 0)
	{
//console.log("Entering WHILE Loop")
	
	var smallestSubArry = currentCollection[0]
	var smallestXFactor = smallestSubArry[2]
 
 
	for (var i=1; i < currentCollection.length; i++) {
	 var nextSubArry = currentCollection[i]
	 var nextXFactor = nextSubArry[2]
 
	 if (nextXFactor < smallestXFactor) {
      smallestSubArry = currentCollection[i]
      smallestXFactor = smallestSubArry[2]    
	 }
	 
	}
//console.log("Smallest X Factor is set to  " +smallestXFactor)

  // we now have the smallest xFactor, now select out the ones that match
  // and push them into tempSorted

	 for (var i=0; i < currentCollection.length; i++) {
	  var nextSubArry2 = currentCollection[i]
	  var nextXFactor2 = nextSubArry2[2]
  
	  if(nextXFactor2 == smallestXFactor) {
	   tempSorted.push(nextSubArry2)
	   indexesToRemove.push(i)   
	  }
	 }

	 while (indexesToRemove.length > 0) {
	  var r = indexesToRemove.pop()
	  currentCollection.splice(r,1)
	  breakIndex--
	 } 
//console.log("Smallest X Factor " +smallestXFactor)

	 } // end of main while loop

	// we should have an empty currentCollection
//console.log("Temp Sorted length is " +tempSorted.length)
	 for (var i=0; i < tempSorted.length; i++) {
	  currentCollection.push(tempSorted[i])
	 }
 
     
 }

 function keyValue2(obj1, key1) {

	 
  for (const [key, value] of Object.entries(obj1)) {
	  //console.log("Key is ..." + key1)
	  
   if (`${key}` == "bounding_box") {  
    
    // create the x and y Factors
    var topLeftX = value.top_left_x
    var topLeftY  =  value.top_left_y
	// no two points on the doc can be exactly the same, right?
	var positionId = topLeftX.toString() + topLeftY.toString()
    const subArry1 = [key1,obj1,topLeftX, topLeftY, positionId]
    currentCollection.push(subArry1)  
    break
   }

   if (typeof(value) === 'object' && key != "bounding_box") {
    keyValue2(value,`${key}`)
    continue
   }

   if (Array.isArray(value)) {
    value.forEach(keyValue2)
    continue
   }

  }

 }
 
 const keyValue = (input) => {
      if(input){
        let entries = Object.entries(input)
        let keyValuePair = {}
        entries.map((key) => {
          if(typeof(key[1]) === 'object'){
            let subEntries=Object.entries(key[1])
            let subEntities = {}
            subEntries.map((sub) => {
            if(typeof(sub) === 'object'){
              if(sub[0] !== 'data' && sub[0] !== 'Urgent' && sub[0] !== 'Barcodes'){
                subEntities[sub[0]] = sub[1]['data']
              }else if(sub[0] === 'data'){
                keyValuePair[key[0]] = sub[1]
              }
              if(sub[0] === 'Urgent' || sub[0] ==='Barcodes'){
                  if(sub[1].length){
                    sub[1].map((s,index)=> {
                      subEntities[index] = s['data']
                    })
                  }else{
                    subEntities[sub[0]] = ''
                  }
              }
              if(key[0] !== 'Document_Type')
              keyValuePair[key[0]]=subEntities
            }
            })
          } else{
            keyValuePair[key[0]]= key[1]
          }
        })
        return keyValuePair
      }
    }
  
    function onClickAsideHeaderTab(e) {
      setSelectedAsideHeaderTab(e.target.id)
    }
  
    function onClickAsideSubHeaderTab(e) {
      setSelectedAsideSubHeaderTab(e.target.id)
    }
  
    function generateBoundingBoxStyle(canvasObj,bounBoxObj, colorIdx) {

// the width of thge page is assumed to always be 8.5 inches
// so this first number is based on 300 dpi times 8.5 equals 2559	  
	  var xTotalPageUnits = 2559
	  
// this second number needs to be extracted from the height of the document	  
//	  var yTotalPageUnits = 3594
	  
	  // original values were 2559 and 3619
	  
	  var page = canvasObj.page
      var xFactor = canvasObj.xFactor // canvas width in px
      var yFactor = canvasObj.yFactor // canvas height in px
 
 // lets get an aspect ration
	  var aspectRatio = parseFloat(yFactor) / parseFloat(xFactor)
	  
//	  console.log("Aspect Ratio is " +aspectRatio)
	  
	  var yTotalPageUnits = xTotalPageUnits * aspectRatio

	  var xFactorInUnits = Math.floor((1/.3935) * xFactor) 
	  var yFactorInUnits = Math.floor((1/.3935) * yFactor)
	  
	  var bottom_right_x = bounBoxObj.bottom_right_x
      var bottom_right_y = bounBoxObj.bottom_right_y
      var top_left_x = bounBoxObj.top_left_x
      var top_left_y = bounBoxObj.top_left_y
	  
	  var positionId = top_left_x.toString() + top_left_y.toString()

	  var cssLeftPercentageUnrounded = (top_left_x / xTotalPageUnits ) * 100 
	  var cssTopPercentageUnrounded = (top_left_y / yTotalPageUnits) * 100 

	  var cssLeftPercentage = Math.round(cssLeftPercentageUnrounded * 100)  / 100
	  var cssTopPercentage = Math.round(cssTopPercentageUnrounded * 100) / 100 
  
	  var divWidthInUnits =  bottom_right_x - top_left_x  
	  var divHeightInUnits = bottom_right_y - top_left_y 
      
	  // now calculate how high and wide as percentages
	  
	  var divWidthasPercentage =  divWidthInUnits / xTotalPageUnits
	  var divHeightasPercentage =  divHeightInUnits / yTotalPageUnits
	  
	  // now apply those percentages to the canvas styled width and height to get pixels
	  var divWidthInPx = Math.round(xFactor * divWidthasPercentage)
	  var divHeightInPx = Math.round(yFactor * divHeightasPercentage)
     	  
	  
//	  var borderColor = getColor(colorIdx)
	  var borderColor = colorCollection[selectedFileIndex][positionId]
	
	  var divStr = 'position:absolute;z-index:5;'
		
// React wants the style as an object

    var divObj = {}
	
      divObj['position'] = 'absolute'
      divObj['zIndex'] = 5	
 	  divObj['top'] = cssTopPercentage + '%'
	  divObj['left'] = cssLeftPercentage + '%'
	  divObj['width'] = divWidthInPx + 'px'
	  divObj['height'] = divHeightInPx + 'px'
	  divObj['border'] = '2px solid ' + borderColor
	  divObj['page'] = page
	  divObj['sequenceId'] = positionId
	  
	  return divObj
	  
} 


function getColor(idx) {

/*	
var colors = [ 'green','red','blue','orange','pink','purple','olive','sienna','black','salmon','teal','violet','olive',
	'green','red','blue','orange','pink','purple','olive','sienna','black','salmon','teal','violet','olive',
	'green','red','blue','orange','pink','purple','olive','sienna','black','salmon','teal','violet','olive']
	
*/

var barColors = [
'rgb(76,220,254)',
'rgb(252,71,146)',
'rgb(83,254,140)',
'rgb(253,112,54)',
'rgb(103,253,66)',
'rgb(50,87,124)',
'rgb(208,122,121)',
'rgb(101,157,210)',
'rgb(189,205,76)',
'rgb(164,52,235)',
'rgb(235,201,52)',
'rgb(52,222,235)',
'rgb(52,58,235)',
'rgb(235,52,137)',
'rgb(52,235,58)',
'rgb(235,52,229)',
'rgb(50,87,124)',
'rgb(101,157,210)',
'rgb(189,205,76)',
'rgb(164,52,235)',
'rgb(235,201,52)',
'rgb(78, 102, 78)',
'rgb(242, 187, 58)',
'rgb(13, 68, 140)',
'rgb(222, 13, 90)',
'rgb(101, 36, 199)',
'rgb(194, 154, 134)',
'rgb(99, 99, 98)',
'rgb(242, 153, 19)',
'rgb(14, 245, 10)',
'rgb(40, 252, 242)',
'rgb(2, 98, 150)',
'rgb(235, 9, 9)',
'rgb(171, 179, 30)',
'rgb(104, 143, 37)',
'rgb(166, 121, 168)',
'rgb(80, 148, 212)',
'rgb(196, 145, 169)',
'rgb(52, 245, 49)',
'rgb(237, 175, 28)',
'rgb(161, 23, 13)',
'rgb(52,222,235)'
]
	
//return colors[idx]
	
	return barColors[idx]
	
}

//function highlightValue(e) {
 const 	highlightvalue = (e) => {
 	

 var sequence = e.target.getAttribute('sequenceid')	
 
 console.log("Sequence passed onMouseOver is " + sequence)
 
 
 
 setHighlighted(sequence)
 
// e.target.style.background = 'rgba(211, 218, 230, .4)'
 
 
 
	
}

//function unHighlightValue(e) {
 const 	unhighlightvalue = (e) => {
 var sequence = e.target.getAttribute('sequenceid')

 
 setHighlighted(0)



// 	e.target.style.background = 'transparent'
	
}	
	

  
 const pageOnRender = () => {
	//console.log('On Render Page Number is ' +pageNumber) 
	 
 } 
 
 	const generateBoundingBoxDivs = () => {
		
		// console.log("SELECTED FILE IDX is " +selectedFileIndex)
	

	  var tempMasterDivCollection = []

      const boundingBoxData = boxData(responses[selectedFileIndex])
	  const canvases = getCanvases()
			
	var canvasesZero = canvases[0]
	var colorIndex = 0
			
	   for (var x=0; x < canvasesZero.length; x++) {

        var currentCanvasesPage = []
 
        var  currentCanvasObj = canvasesZero[x]
  
        var page = currentCanvasObj.page
	
			for(var k=0; k < boundingBoxData.length; k++) {
			  var currentBoundingBoxObj = {}
			  var boxPageNumber = 0 
			  if(typeof(boundingBoxData[k]) === 'object'){
			      
		       let sub2Entries=Object.entries(boundingBoxData[k])
			   sub2Entries.map((sub2) => {
			   
			    if (sub2[0] === 'bounding_box') {
                 currentBoundingBoxObj = sub2[1]
			    }
			    if (sub2[0] === 'page_number') {
                 boxPageNumber = sub2[1]
			    }			    
			  
			})
	
			}  

	 if (boxPageNumber == page)  { 

      var divBoxStyle = generateBoundingBoxStyle(currentCanvasObj, currentBoundingBoxObj, colorIndex)
	 
     colorIndex++
     tempMasterDivCollection.push(divBoxStyle)

     }
	
	}   
	   }
   

    if (tempMasterDivCollection.length > 0) {

     if (masterDivCollection[selectedFileIndex]) {
      masterDivCollection[selectedFileIndex].splice(0, masterDivCollection[selectedFileIndex].length, ...tempMasterDivCollection)
	 } else {
	   	masterDivCollection.push(tempMasterDivCollection) 
	 }
 

    }


setRenderTrigger(Date.now())

  }


 	
 	function getCanvases() {
		
	  
    const r1 = /^.*width[:]{1}\s+([0-9]+)px[;]{1}\s*height[:]{1}\s+([0-9]+)px[;]{1}$/;

	
    const file = authFormRef.current.files[selectedFileIndex][0]
      
      let canvases = []
      let targetIds = []
	  
      if (file.type === 'application/pdf') {
          targetIds.push('pdf-keyValuePairs' + selectedFileIndex)
          targetIds.push('pdf-entities' + selectedFileIndex)
	  }
      let targetDivs = []
      targetIds.forEach((targetId) => {
      targetDivs.push(document.getElementById(targetId))
      })
 
      targetDivs.forEach((targetDiv) => { 
			
		const reactpdfDocumentDivs =  targetDiv.getElementsByClassName('react-pdf__Document')
		const reactpdfDocumentDiv = reactpdfDocumentDivs.item(0)
	    const visibilityDivs = reactpdfDocumentDiv.children
  
		const accumulateCanvases = []
		for (var i=0; i < visibilityDivs.length; i++) {
				
		    const reactpdfPages = visibilityDivs.item(i).getElementsByClassName('react-pdf__Page')
			
			 
             if (reactpdfPages.item(0)) {
			  const reactpdfPage = reactpdfPages.item(0)
			  //console.log(reactpdfPage.getAttribute("class"))
			  const dataPageNumber = reactpdfPage.getAttribute("data-page-number")
             
			 
			 if (reactpdfPage.getElementsByClassName('react-pdf__Page__canvas').length) {				 
				
				const canvasCollection = reactpdfPage.getElementsByClassName('react-pdf__Page__canvas')
                const targetCanvas = canvasCollection.item(0)
				//console.log("Canvas class is " +targetCanvas.getAttribute("class"))
			 const targetCanvasStyleStr = targetCanvas.getAttribute("style")
			 const matches = targetCanvasStyleStr.match(r1);
			 
			 const canvasInfoObj = {
					page:	dataPageNumber,
					xFactor:  matches[1],
					yFactor: matches[2]					
			 }
			  
			 accumulateCanvases.push(canvasInfoObj) 
			 
			  }
			 }

			}
								
		
		  canvases.push(accumulateCanvases)
		
	   
	  
         })
		 //console.log(canvases)
        return canvases
	
    }

  function boxData(input) {

   	
  let boundingBoxData = []
  let entries = Object.entries(input)
  
  var count = 1
  
  entries.map((key) => {
	  if(typeof(key[1]) === 'object'){  // I only want objects
	    let subEntries = Object.entries(key[1])
		subEntries.map((sub) => {
			var tempBoxObj = {}
			var collectBoxObjects = []
			if(typeof(sub[1]) === 'object' && !(Array.isArray(sub[1]))) {
		     let sub2Entries=Object.entries(sub[1])
			 sub2Entries.map((sub2) => {
			   
			  if (sub2[0] === 'bounding_box') {
                tempBoxObj['bounding_box'] = sub2[1]
			  }
			  
		      if (sub2[0] === 'page_number') {
                tempBoxObj['page_number'] = sub2[1]
			  }
			  
			})
			

			} else if(Array.isArray(sub[1])) {	

			  for(var k=0; k < sub[1].length; k++) {
			    var tempArrayedBoxObj = {}
				  
			   if(typeof(sub[1][k]) === 'object'){
		       let sub2Entries=Object.entries(sub[1][k])
			   sub2Entries.map((sub2) => {
			   
			    if (sub2[0] === 'bounding_box') {
                 tempArrayedBoxObj['bounding_box'] = sub2[1]
			    }
			    if (sub2[0] === 'page_number') {
                 tempArrayedBoxObj['page_number'] = sub2[1]
			    }
			    
			  
			})
	
			}  
			  collectBoxObjects.push(tempArrayedBoxObj)
			  }
			  
		    }
	  
			if ( Object.keys(tempBoxObj).length > 0  ) {
              boundingBoxData.push(tempBoxObj)
	         }  
			
			if(collectBoxObjects.length > 0) {
			  for(var m=0; m < collectBoxObjects.length; m++) {
               boundingBoxData.push(collectBoxObjects[m])
              }				  
			}	
			 

	})

  } 
  })
  return boundingBoxData
}

  const isFile = authFormRef.current && authFormRef.current.files.length > 0 &&
        authFormRef.current.files[selectedFileIndex] &&
        authFormRef.current.files[selectedFileIndex][0]
		
    useEffect(() => {
      if (cookies && cookies.orgId && cookies.authKey) {
        forms.init(cookies.orgId, cookies.authKey)
            .then(response => {
              if (response['state'] === 'Success') {
                setAuthForm({
                  ...authForm,
                  orgId: cookies.orgId,
                  authKey: cookies.authKey,
                })
                setIsLoading(false)
                setIsAuthModalShowing(false)
              } else {
                setIsAuthModal(true)
                setIsAuthModalShowing(true)
              }
            })
            .catch(error => {
              setIsAuthModal(true)
              setIsAuthModalShowing(true)
            })
      } else {
     
      }
      pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`
      // Set size based on screen size on page load
      const app = document.getElementById('root')
      const appHeight = app.clientHeight
      const appWidth = app.clientWidth
      setAppSize({
        width: appWidth,
        height: appHeight,
      })
      setAuthForm({
        ...authForm,
        authKey: process.env.REACT_APP_AUTHKEY || '',
        orgId: process.env.REACT_APP_ORGID || '',
      })
    }, [])
  
    useEffect(() => {
		

      setJsonCopiedIndex(null)
    }, [selectedAsideHeaderTab])
	
   useEffect(() => {
	   
	var preLoadedZipFileObj
	var idx
 
console.log("Zip File Ref File "  +preLoadedZipFileRef.current.zipfile)
console.log("Zip File Ref Index "  +preLoadedZipFileRef.current.index)

   if (preLoadedZipFileRef.current) {
	   
    var file = preLoadedZipFileRef.current.zipfile
    var currentIndex = parseInt(preLoadedZipFileRef.current.index)
	var pairs = preLoadedZipFileRef.current.pairs
   
    if (Number.isInteger(currentIndex)&& currentIndex > 0) {
	   console.log("Dropped into first conditional")

	   idx =  currentIndex - 1
// decrement the index and update the ref	
	   preLoadedZipFileObj = {
							zipfile: file,
							index: idx,
							pairs: pairs		
		   }

//		    preLoadedZipFileRef.current = preLoadedZipFileObj
			setPreLoadedZipFileRef(preLoadedZipFileObj)
			
   setTimeout(() => {
    loadZipFile(file,pairs,idx)
  }, 2000)			
			
	
    } else if (Number.isInteger(currentIndex) && currentIndex == 0) {
	   console.log("Dropped into second conditional - earasing")

       idx = null
// we have reached zero so flatten out the ref 
//		preLoadedZipFileObj = {}	
preLoadedZipFileObj = null
//		preLoadedZipFileRef.current = preLoadedZipFileObj
 setPreLoadedZipFileRef(preLoadedZipFileObj)
    } else {
	 idx = null	
	 console.log("No current Index is set")	
	}

 
/*  
	if (preLoadedZipFileObj) { 	
     setPreLoadedZipFileRef(preLoadedZipFileObj)		
    }
*/	
  } 
  
	
}, [authForm])

var highlightStyle
	
	
    return (
        <div
            className='App'
            id='App'
            style={{
              width: `${appSize.width}px`,
              height: `${appSize.height}px`,
            }}
        >
          <div id='home'>
            <aside id='left-aside'>
              <header id='left-aside-header'>
 
              </header>
              <div className='image-thumb-container'>
                {authFormRef.current && authFormRef.current.files.length > 0 &&
                    authFormRef.current.files.map((file, i) => {
                      return (
                          <div 
							key={'file-thumb-' + i}
							className='documentNum'>
							  { 'Document ' + (i + 1).toString() }
                            <div title="Preview Page"
                                className={
                                  i === selectedFileIndex ?
                                      'image-thumb selected-image-thumb' :
                                      'image-thumb' 
                                }
                                onClick={() => {
                                  setSelectedFileIndex(i)
                                  setPageNumber(1)
                                }}
                            >
 
                              {documentName[i]}
                       
							</div>
                          </div>
                      )
                    })
                }
              </div>
			  

              <div
                  className='upload-container'
                  onClick={onClickUploadButton}
              >
			  { authFormRef.current && authFormRef.current.files.length > 0 &&
	            <>
                <img
                    src={Download}
                    alt='upload'                     title = "Upload Zip file"

                />
			
			  </>
			  } 
			 
  
                <input
                    ref={inputRef}
                    name='file'
                    type='file'
                    id='file-input'
                    onChange={onChangeFile}
                />
  
              </div>
            </aside>
            <main id='app-main'>
              <header className='main-header'>
				<img src={FormsLogo} alt='Consensus Logo' />
              </header>
              {numPagesArr[selectedFileIndex] > 0 &&
                  <header className='page-number-header'>
                    <p className='header-text'>{pageNumber + '/' + numPagesArr[selectedFileIndex]}</p>
                  </header>
              }
              {authFormRef.current && authFormRef.current.files.length > 0 &&
                  <>
                    <div className='prev-page-arrow' title = "Left Rotate 90 Degree">
                      {(pageNumber > 1) &&
                          <p
                              onClick={() => {setPageNumber(pageNumber -1)}}
                          >
                            <img src={Left} alt='back' title = "Back page" />
                          </p>
                      }
  
 
  
                    </div>
                    <div className='next-page-arrow' title = "Right Rotate 90 Degree">
                      {numPagesArr[selectedFileIndex] > pageNumber &&
                          <p
                              onClick={() => {
                                setPageNumber(pageNumber + 1)
								
                              }}
                          >
                            <img src={Right} alt='next' title = "Next page" />
                          </p>
                      }
 
  
                    </div>
                    <div className='pdf-view flex justify-center'>
                      {authFormRef.current.files.map((file, i) => {
                        if (isFile && authFormRef.current.files[i][0].type === 'application/pdf') {
                          return (
                              <>
                                <div
                                    key={'pdf-keyValuePairs' + i}
                                    id={'pdf-keyValuePairs' + i}
                                    className={
                                      i === selectedFileIndex  &&
                                      selectedAsideSubHeaderTab === 'keyValuePairs' ?
                                          '' :
                                          'invisible'
                                    }
                                >
                                  <Document
								      file={authFormRef.current.files[i][0]}
                                      onLoadSuccess={onDocumentLoadSuccess}
                                      onLoadError={console.error}
                                  >
                                    {[...Array(numPagesArr[i]).keys()].map((page, j) => {
                                      return (
                                          <div
                                              key={'pdf-keyValuePairs' + i + '-page-' + j}
                                              className={pageNumber === page + 1 ? 'visible' : 'invisible'}
											  style={{
												position: 'relative'
											  }}
                                          >
                                            <Page
                                                pageNumber={j + 1}
                                                width={
                                                  document.getElementsByClassName('pdf-view') &&
                                                  document.getElementsByClassName('pdf-view')[0] ?
                                                      document.getElementsByClassName('pdf-view')[0].clientWidth :
                                                      0
                                                }
											    onRenderSuccess={generateBoundingBoxDivs}
												onLoadError={console.error}
                                            />
											{
												masterDivCollection[selectedFileIndex] &&
												masterDivCollection[selectedFileIndex].map((divStyle) => {
	
												    if (divStyle.page == pageNumber) {

                                                    if (highlighted === divStyle.sequenceId) {
                                                      highlightStyle = { background: 'rgba(211, 218, 230, .5)' }
													  } else {
													  highlightStyle = { background: 'transparent' }	
													  }														  
													return (
													<div
														className={'boundingBoxDiv'}
														sequenceid={divStyle.sequenceId}
														onMouseOver={highlightvalue}
														onMouseOut={unhighlightvalue}
														style={{...divStyle, ...highlightStyle}}
														
													>
													</div>
													
													)
												}										
											     })	
																				  
											
											}
																																			
                                          </div>
                                      )
                                    })}
                                  </Document>
                                </div>
                                <div
                                    key={'pdf-entities' + i}
                                    id={'pdf-entities' + i}
                                    className={
                                      i === selectedFileIndex  &&
                                      selectedAsideSubHeaderTab === 'entities' ?
                                          '' :
                                          'invisible'
                                    }
                                >
                                  <Document
                                      file={authFormRef.current.files[i][0]}
                                      onLoadSuccess={onDocumentLoadSuccess}
                                      onLoadError={console.error}
                                  >
                                    {[...Array(numPagesArr[i]).keys()].map((page, j) => {
                                      return (
                                          <div
                                              key={'pdf-entities' + i + '-page-' + j}
                                              className={pageNumber === page + 1 ? 'visible' : 'invisible'}
											  style={{
												position: 'relative'
											  }}
                                          >
                                            <Page
                                                pageNumber={j + 1}
                                                width={
                                                  document.getElementsByClassName('pdf-view') &&
                                                  document.getElementsByClassName('pdf-view')[0] ?
                                                      document.getElementsByClassName('pdf-view')[0].clientWidth :
                                                      0
                                                }
                                                onLoadError={console.error}
                                            />
											{
												masterDivCollection[selectedFileIndex] &&
												masterDivCollection[selectedFileIndex].map((divStyle) => {
	
												    if (divStyle.page == pageNumber) {
														
                                                   if (highlighted === divStyle.sequenceId) {
                                                      highlightStyle = { background: 'rgba(211, 218, 230, .5)' }
													  } else {
														highlightStyle = { background: 'transparent' }	
													  }								
														
													return (
													<div
														className={'boundingBoxDiv'}
														sequenceid={divStyle.sequenceId}
														onMouseOver={highlightvalue}
														onMouseOut={unhighlightvalue}
														style={{...divStyle, ...highlightStyle}}
														
													>
													</div>
													
													)
												}										
											     })	
																				  
											
											}		
											
											
                                          </div>
                                      )
                                    })}
                                  </Document>
                                </div>					
                              </>
                          )
                        } else {
                          return (
                              <>
                                {isFile &&
                                    <>
                                      <div
                                          key={'image-keyValuePairs' + i}
                                          className={
                                            i === selectedFileIndex &&
                                            selectedAsideSubHeaderTab === 'keyValuePairs' ?
                                                'image-container' :
                                                'image-container invisible'
                                          }
                                      >
                                        <canvas
                                            id={'image-canvas-keyValuePairs-' + i}
                                            className={
                                              i === selectedFileIndex ?
                                                  'image-canvas' :
                                                  'image-canvas opacity-0'
                                            }
                                        />
                                        <img
                                            id='rendered-image-keyValuePairs'
                                            src={URL.createObjectURL(authFormRef.current.files[i][0])}
                                            alt=''
                                        />
                                      </div>
                                      <div
                                          key={'image-entities' + i}
                                          className={
                                            i === selectedFileIndex &&
                                            selectedAsideSubHeaderTab === 'entities' ?
                                                'image-container' :
                                                'image-container invisible'
                                          }
                                      >
                                        <canvas
                                            id={'image-canvas-entities-' + i}
                                            className={
                                              i === selectedFileIndex ?
                                                  'image-canvas' :
                                                  'image-canvas opacity-0'
                                            }
                                        />
                                        <img
                                            id='rendered-image-entities'
                                            src={URL.createObjectURL(authFormRef.current.files[i][0])}
                                            alt=''
                                        />
                                      </div>
                                    </>
                                }
                              </>
                          )
                        }
                      })}
                    </div>
                  </>
              }
			  
   { !(authFormRef.current && authFormRef.current.files.length > 0) &&
   
	<>
	<div
  className='no-open-files-upload-container'
	>
    <img
        src={Upload}
        alt='upload'                     
		title = "Upload Zip file"
		 onClick={onClickUploadButton}
	/>
	<div className='upload-hint'>Upload a zipfile containing JSON and PDF.</div>
	</div>
	  
    </>	  
  }
  
              {authFormRef.current.files[0] &&
                  <footer>
                    <button 
                        className={
                          showingResponses[selectedFileIndex] ?
                              'footer-button temphide' :
                              'footer-button temphide'
                        }
                        onClick={
                          showingResponses[selectedFileIndex] ?
                              () => alert('You already have a response') :
                              // onClickUploadFileNpm
                              onClickUploadFile
                        }
                    >
                      {
                        showingResponses[selectedFileIndex] ?
                            'Uploaded Zip File' :
                            'Analyze'
                      }
                    </button>

                  </footer>
              }
            </main>
            <aside 
				id='right-aside'
				style={{backgroundColor: !(authFormRef.current && authFormRef.current.files.length > 0) ? '#F0F0F0' : 'white' }}
			>
              <header 
					className='aside-header'
				    style={{backgroundColor: !(authFormRef.current && authFormRef.current.files.length > 0) ? '#F0F0F0' : 'white' }}	
			  >
                <button
                    className={
                      selectedAsideHeaderTab === 'values' ?
                          'aside-header-tab selected-aside-header-tab' :
                          'aside-header-tab'
                    }
					style={{visibility: !(authFormRef.current && authFormRef.current.files.length > 0) ? 'hidden' : 'visible' }}
                    id='values' title = "Values"
                    onClick={onClickAsideHeaderTab}
                    
                >
                  Values
                  
                </button>
	
                <button
                    className={
                      selectedAsideHeaderTab === 'json' ?
                          'aside-header-tab selected-aside-header-tab' :
                          'aside-header-tab'
                    }
					style={{visibility: !(authFormRef.current && authFormRef.current.files.length > 0) ? 'hidden' : 'visible' }}
                    id='json' title = "JSON"
                    onClick={onClickAsideHeaderTab}
                >
             


                  JSON
                </button>
              </header>	
              { keyValue2Collection[selectedFileIndex] &&
			  
                  <>
					{ selectedAsideHeaderTab === 'values'  &&
                          <> 				  
                  <div className='values-container'>
    
                    
												

												{																																							
    <KeyValue
		data={keyValue2Collection[selectedFileIndex]}
		colors={colorCollection[selectedFileIndex]}
		highlight={highlighted}
		highlightvalue={highlightvalue}
		unhighlightvalue={unhighlightvalue}
		key={selectedFileIndex}
	/>
												}											
                      
                    </div>
</>
					}
                  </>
			
              }
              {selectedAsideHeaderTab === 'json' &&
//                  <>
 //                  {showingResponses[selectedFileIndex] &&
                        <>
                          <div className='json-container' style = {{"padding": "5px"}}>

                            {
  
                            <JsonTree
                                data={responses[selectedFileIndex]}
                                editButtonElement={<button>Update</button>}
                                cancelButtonElement={<button>Cancel</button>}
                                minusMenuElement={<span></span>}
                                plusMenuElement={<button>Add Element</button>}
                                readOnly={(keyName, data, keyPath, deep, dataType) => {return readOnlyFuncion(keyName, data, keyPath, deep, dataType)}}
                            />
                            }
                          </div>
                          <header className='aside-header'>
                            <button
                                className={
                                  jsonCopiedIndex === selectedFileIndex ?
										'aside-header-tab selected-aside-header-tab' :
										'aside-header-tab'
                                }
                                onClick={onClickCopy}
                            >
                              {jsonCopiedIndex === selectedFileIndex ?
                                  'Copied' :
                                  'Copy'
                              }
                            </button>
                            <button
                                className={
                                  jsonCopiedIndex === selectedFileIndex ?
										'aside-header-tab selected-aside-header-tab' :
										'aside-header-tab'
                                }
                                onClick={saveUsingHandle}
                            >
                              {jsonCopiedIndex === selectedFileIndex ?
                                  'Saved' :
                                  'Save'
                              }
                            </button>
                          </header>
                        </>
 //                   }
//                  </>
              }
            </aside>
          </div>
        </div>
    )
  }
  
  export default Main
  