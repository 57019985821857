import React, {useEffect, useState, useRef} from 'react'

function KeyValue(props) {

 const spanRef = useRef()
 
 const [editState, setEditState] = useState(null)

 const [currentValue, setCurrentValue] = useState()

 const [keyValueCollection, setKeyValueCollection] = useState(props.data)
 
 const [colorMap, setColorMap] = useState(props.colors)
 
 const flag = false
 
 const highlight =  props.highlight
 
 const highlightvalue = props.highlightvalue
 
 const unhighlightvalue = props.unhighlightvalue
 
 const barColors = [
'rgb(76,220,254)',
'rgb(252,71,146)',
'rgb(83,254,140)',
'rgb(253,112,54)',
'rgb(103,253,66)',
'rgb(50,87,124)',
'rgb(208,122,121)',
'rgb(101,157,210)',
'rgb(189,205,76)',
'rgb(164,52,235)',
'rgb(235,201,52)',
'rgb(52,222,235)',
'rgb(52,58,235)',
'rgb(235,52,137)',
'rgb(52,235,58)',
'rgb(235,52,229)',
'rgb(50,87,124)',
'rgb(101,157,210)',
'rgb(189,205,76)',
'rgb(164,52,235)',
'rgb(235,201,52)',
'rgb(78, 102, 78)',
'rgb(242, 187, 58)',
'rgb(13, 68, 140)',
'rgb(222, 13, 90)',
'rgb(101, 36, 199)',
'rgb(194, 154, 134)',
'rgb(99, 99, 98)',
'rgb(242, 153, 19)',
'rgb(14, 245, 10)',
'rgb(40, 252, 242)',
'rgb(2, 98, 150)',
'rgb(235, 9, 9)',
'rgb(171, 179, 30)',
'rgb(104, 143, 37)',
'rgb(166, 121, 168)',
'rgb(80, 148, 212)',
'rgb(196, 145, 169)',
'rgb(52, 245, 49)',
'rgb(237, 175, 28)',
'rgb(161, 23, 13)',
'rgb(52,222,235)'
]



  function saveEdit(e) {
	  
	  console.log("Current Value is saved")
	  
	
	var sequence = parseInt(e.target.getAttribute('sequenceid'))


    keyValueCollection.map((subarry2,j) => {
     if(sequence ==	parseInt(j)) {	

        if (!(subarry2[1].hasOwnProperty('originalData'))) {
			subarry2[1].originalData = subarry2[1].data.toString()
        }			

		subarry2[1].data = currentValue.toString()	  
	 }
	})

	setEditState(null)

	
	  
  }

  function cancelEdit(e) {
	  
	setEditState(null)  
	  
  }
  
  function editValue(e) {
	  
	setCurrentValue(e.target.value)
	
    setEditState(parseInt(e.target.getAttribute('sequenceid')))
		  
  }

 
return(
<>
{ keyValueCollection.map((subarry1,i) => {

//console.log("Color value passed down is " + colorMap[subarry1[4]])
  
  var currentBarColor = colorMap[subarry1[4]]
//  var barWidth = (subarry1[1].confidence_score * 225)
  

 var barWidth = (subarry1[1].confidence_score * 50).toString()
  
 barWidth = barWidth.concat("%")
 
 var highlightStyle = {}
 
 if (subarry1[4] === highlight) {
	  console.log("Highlight style matches in conditional")
	  highlightStyle = {background: 'rgb(115, 115, 117)'}
      
 }
  
  if (editState !== i) {
	  
	  
  return (
   
   <div 
    key={'keyValueItem' + i}
	className="keyValItem"
	
	style={{...highlightStyle}}

	>
	<div className="scoreWrapperDiv">
	<span className="boxOuterColorBar" style={{	backgroundColor: currentBarColor,
												width: barWidth}}
	sequenceid={subarry1[4]}											
	onMouseOver={highlightvalue}
	onMouseOut={unhighlightvalue}
												></span>
    <span className="confidenceScore">{Math.round((subarry1[1].confidence_score * 100))}%</span>
	</div>
	<div className="dataKeyValue">{subarry1[0].toString()}:</div>
	<div className="keyValueDivider"></div>
	<div className="dataKeyValue">

  	<span
	    ref={spanRef}
	    value={subarry1[1].data.toString()}
		sequenceid={i}
		onClick={editValue}
	>{subarry1[1].data.toString()}</span>
	
	</div>
  </div>
	


  )} else {
	  
   return (
 
    <div 
	key={'keyValueItem' + i}
	className="keyValItem"
	
	style={{...highlightStyle}}

	>
	<div className="scoreWrapperDiv">
	<span className="boxOuterColorBar" style={{	backgroundColor: currentBarColor,
												width: barWidth}}
	sequenceid={subarry1[4]}											
	onMouseOver={highlightvalue}
	onMouseOut={unhighlightvalue}											
												
												></span>
    <span className="confidenceScore">{Math.round((subarry1[1].confidence_score * 100))}%</span>
	</div>
	<div className="dataKeyValue">{subarry1[0].toString()}:</div>
	<div className="keyValueDivider"></div>
	<div className="dataKeyValue">
	<span
		sequenceid={i}
	>
	
	<input 
	defaultValue={subarry1[1].data.toString()}
	value={currentValue}
	type="text" 
	className="valDivInput"
	style={{backgroundColor: "white"}}
	onChange={(e) => setCurrentValue(e.target.value)}
	/>

	<button
	value={subarry1[1].data.toString()}
	className="cancel"
	sequenceid={i}
	type="button"
	onClick={saveEdit}
	>Save
	</button>
	
	<button
	value={subarry1[1].data.toString()}
	className="cancel"
	type="button"
	onClick={cancelEdit}
	>Cancel
	</button>
	
	</span>
	</div>
  </div>
  
  ) }

 
})

}
</>
) 

}

export default KeyValue;