// Dependencies
import React, {useState, useEffect} from 'react'
import Main from './Main';
import Login from './Login';


import { Route,Routes} from 'react-router-dom';

//import ReactDOM from "react-dom";



function App() {

  const [isLoggedIn, setIsLoggedIn] = useState(false);
  useEffect(() => {
    const items = JSON.parse(localStorage.getItem('isLogin'));
    if (items) {
      setIsLoggedIn(items);
    }
  }, []);
  return (
    <div className='App'>
      {/* <Login /> */}
     {/* // <BrowserRouter> */}
      <Routes>
        
      <Route exact path="/"  element = {<Login />}/>
      <Route path = "/login"  element = {<Login />}/>

          {/* <Route  path="login" component={Login} /> */}
          {/* <Route exact path="/Main" component={Main} /> */}
           { isLoggedIn &&  
                 <Route path = "/main"  element = {<Main />} />

              // <Route  path="/main" component={Main} />
           }
           </Routes>
        {/* </BrowserRouter> */}
    </div>
  );
}

export default App
