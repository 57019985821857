import React, { useState } from 'react';
//import ReactDOM from "react-dom";


//import PropTypes from 'prop-types';

//import '../styles/App.css';
import FormsLogo from "../images/login-logo.png"
//import '../index.css';
//import '../fonts/font.css';


//import '../styles/e-fax.css';
import '../styles/login-corp-admin.css';
import { useNavigate } from 'react-router-dom';

//import '../login-';
import Main from './Main';



function Login() {
  console.log("Hello");
  const navigate = useNavigate();
  const [userName, setUserName] = useState('') // useState to store First Name
  const [password, setPassword] = useState('') // useState to store First Name
 const [errorMessages, setErrorMessages] = useState({});
  //const [isSubmitted, setIsSubmitted] = useState(false);
 const [authenticated, setauthenticated] = useState(false);
//     localStorage.getItem(localStorage.getItem("authenticated") || false)
//   );


  // User Login info
  const database = [
    {
      userName1: "test",
      password1: "test"
    },
    
  ];

  const errors = {
    userName: "invalid username",
    password: "invalid password"
  };

  const handleSubmit = () => {
    //Prevent page reload
    //event.preventDefault();
    var isChecked = validateForm();
    console.log(isChecked);
    
    //var { userName, password } = document.forms[0];
  
    // Find user login info
    //const userData = database.find((user) => user.userName1 === userName.value);
  //console.log("Username " , userName)
    // Compare user info

    
    if (isChecked == 1) {
        localStorage.setItem('isLogin', JSON.stringify(true));
        setauthenticated(true);
       // redirect(navigate("/login"));
       navigate("/main");
    } 
else{
    localStorage.setItem('isLogin', JSON.stringify(false));
    setauthenticated(false);
    navigate("/Login");

}  };

  // Generate JSX code for error message
//   const renderErrorMessage = (userName) =>
//   userName === errorMessages.name && (
//       <div className="error">{errorMessages.message}</div>
//     );

 
  function validateForm() {
    // Check if the First Name is an Empty string or not.
    console.log("username : " , userName);
    if (userName.length == 0) {
      alert('Invalid Form, User Name can not be empty');
      
      return
    }
    console.log(userName.toLowerCase());
    if (userName !== "PsTeamWork") {
        alert('Invalid userName ' + userName);
        return
      }
  
    // Check if the Email is an Empty string or not.

    if (password.length == 0) {
      alert('Invalid Form, password can not be empty')
      return
    }
    if (password !== "w67D2BSM9iAilrX") {
        alert('Invalid password : ' + password);
        
        return
      }
    // if all the conditions are valid, this means that the form is valid
   // localStorage.key.setauthenticated = true
    //alert('Form is valid')
    return 1;
  }
  
  // const [userName, setUserName] = useState();
  //  const [password, setPassword] = useState();
  
  return(
    
    <>
<div>
   
    <div className="container">

        <div className="white-bg">

            <header>
                <div className="main-header-login">
                    <div className="login-logo">
                        <img src={FormsLogo} alt="" />
                    </div>
                    <div style={{"float":"right"}}>                
                        {/* { <h2>eFax - Epic User Portal</h2>  } */}
                    </div>
                    <div className="clear"></div>
                </div>
                <div className="clear"></div>
            </header>
           

            <div className="login-main-content">

                <div className="login-page">

                    <div className="tab-part" style={{"width":"60%"}}>

                        <div id="horizontalTab" className="resp-easy-accordion" style={{"display":" block", "width": "100%", "margin":" 0px"}}>

                        

                            <div className="clear"></div>

                            <div className="resp-tabs-container">

                                <div id="adminDiv" className="adminBox">

                                <ul className="resp-tabs-list" style={{"display": "none"}}>
                                {/* <li id="adminTab"   className="resp-tab-active" aria-controls="tab_item-0" role="tab" data-ol-has-click-handler=""  >
                                    Admin
                                </li>                                
                                <li id="developerTab" className="resp-tab-item" aria-controls="tab_item-1" role="tab" data-ol-has-click-handler="" >                                
                                    User                                    
                                </li> */}
                            </ul>

                                        <input type="hidden" defaultValue="Login" name="formName" id="formName" />

                                        <h2 className="adminBox">Login</h2>

                                        <div className="required">
                                            * Required
                                        </div>

                                        <div className="clear"></div>

                                        <div style={{"height":"80px"}}>

                                                    {/* <div className="label-text" style = {{"width": "30%" }}> */}
                                                    <div className="label-text" style = {{"width": "30%" }}>

                                                    Username
                                                        <span id="adminIdReqDiv1" className="label-asterisk">*</span>
                                                    </div>                                                  
                                            
                                                    <div  style = {{"height":"40px"}}>
                                                      
                                                       <input type="text" className = "textInput" defaultValue = "" title ="Password" autoComplete = "off"  name = "userName" id = "userName" style = {{ "height" : "25px" , "width" : "57%" }}
                                                       placeholder="User Name"
                                                       onChange={(e) => setUserName(e.target.value)}         

                                                       
                                                       /> 
                                                    </div>
                                                    
                                            
                                                    <div className="label-text" style = {{"width": "30%" }}>
                                                    Password
                                                        <span id="adminIdReqDiv1" className="label-asterisk">*</span>
                                                    </div>                                                  
                                            
                                                    <div className="form-input" style = {{"height" : "40px"}}>
                                                        <input type="password" defaultValue="" name="password" id="password" className="textInput"  style= {{"height": "25px" ,"width":"57%" ,  }} 
                                                        placeholder="Password"
                                                        onChange={(e) => setPassword(e.target.value)}
                                                        />
                                                    </div>
                                        </div>

                                        
                                        <div className="login-info-bottom-left"></div>

                                        <div className="login-info-bottom-right">

                                            <div className="login-info-bottom" >

                                                <input type="button" defaultValue=" Login" id="submit"  onClick={() =>     handleSubmit() }  />

                                               
                                            </div>
                                        </div>
                                        <div className="clear"></div>
                                    

                                </div>

                                {/* <div id="userDiv" className="userBox" style={{"display":"none"}}>

                                       
                                        <h2 id="ulog">User Login</h2>

                                        <div className="required">
                                            * Required
                                        </div>



                                        <div style={{"height":"100px"}}>

											<div className="label-text" style={{"width": "45%" }}>
                                                AppId
                                                <span id="adminIdReqDiv1" className="label-asterisk">*</span>
                                            </div>                                                  
                                    
                                            <div  style={{"height":"40px"}}>
                                               <input type="text" name="userAppId" id="userAppId" className="textInput" defaultValue="" title ="Password" autoComplete="off" style={{"height":"25px", "width":"53%" }}/>
                                            </div>
											
                                            <div className="label-text" style={{" width": "45%" }}>
                                                Username
                                                <span id="adminIdReqDiv1" className="label-asterisk">*</span>
                                            </div>                                                  
                                    
                                            <div  style={{"height":"40px"}}>
                                               <input type="text" name="userName" id="userName" className="textInput"defaultValue="" title="Password" autoComplete="off" style={{"height": "25px", "width":"53%"  }}/>
                                            </div>
                                            
                                    
                                       
                                            <div className="label-text" style={{" width": "45%"}} >
                                                Password
                                                <span id="adminIdReqDiv1" className="label-asterisk">*</span>
                                            </div>                                                  
                                    
                                            <div className="form-input" style={{"height":"40px"}}>
                                                <input type="password" name="userPwd" id="userPwd" className="textInput" style={{"height":" 25px" ,"width":"53%",  }}/>
                                            </div>
                                    </div>


                                        <div className="login-info-bottom-left"></div>

                                        <div className="login-info-bottom-right">

                                            <div className="login-info-bottom">


                                                <input type="button" defaultValue="User Login" id="button" />

                                                <a href="Forgot_pswd.html?type=admin"
                                                    name="forgotPassword" id="forgot_pswd">
                                                    Forgot my Password
                                                </a>
                                            </div>
                                        </div>

                                        <div className="clear"></div>

                                </div> */
                                }

                                
                            </div>
                        </div>
                    </div>
                    <div className="clear"></div>
                </div>
            </div>
        </div>


        <div id="cookie-banner-container">
            <div id="cookie-banner-text">
                We use cookies to ensure that we give you the best experience on our website. By continuing to use this
                website you are giving consent to cookies being used. To learn more visit our
                <a id="cookie-banner-policy" href="https://www.efax.co.uk/privacy#cookie_policy">Cookie Policy &gt;&gt;</a>
            </div>
            <div id="cookie-banner-button">
                <button id="cookie-understand">I Understand </button>
            </div>
        </div>

        <div className="bottom-shadow"></div>
        <footer className = "footer" style = {{"position":"relative","fontFamily": "source_sans_proregular, arial, helvetica, sans-serif"}}>

            <div className="text-center">
        <ul>
        <li>
        <a href="http://enterprise.efax.com/">
            Online Faxing
        </a>
        </li>
        <li>
        <a href="http://enterprise.efax.com/solutions">
            Solutions
        </a>
        </li>
        <li>
        <a href="http://enterprise.efax.com/industry">
            Industry
        </a>
        </li>
        <li>
        <a href="http://enterprise.efax.com/resources">
            Resources
        </a>
        </li>
        <li>
        <a href="http://enterprise.efax.com/events">
            Events
        </a>
        </li>
        <li>
        <a href="http://enterprise.efax.com/company/j2-global-cloud-solutions">
            Cloud Services
        </a>
        </li>
        <li>
        <a href="http://enterprise.efax.com/blog">
            Blog
        </a>
        </li>
        <li>
        <a href="http://enterprise.efax.com/contact-us">
            Contact Us
        </a>
        </li>
        </ul>
        </div>

        <div className="text-center">
        <ul>
        <li>
        <a href="http://enterprise.efax.com/online-fax-solutions/small-business">
            Small Business
        </a>
        </li>
        <li>
        <a href="http://enterprise.efax.com/online-fax-solutions/medium-business">
            Medium Size Business
        </a>
        </li>
        <li>
        <a href="http://enterprise.efax.com/online-fax-solutions/enterprise">
            Enterprise Business
        </a>
        </li>
        </ul>
        </div>

        <div className="text-center">
        <ul>
        <li>
        <a href="http://enterprise.efax.com/company/customer-agreement?showAgreement=YES">
            Customer Agreement
        </a>
        </li>
        <li>
        <a href="http://enterprise.efax.com/company/privacy-policy">
            Privacy Policy
        </a>
        </li>
        <li>
        <a href="http://enterprise.efax.com/company/legal-notices">
            Legal Notices
        </a>
        </li>




        </ul>
        </div>

        <div className="clear"></div>

        <div className="clear"></div>

        <div className="copyright text-center">
        &copy; 2022&nbsp;Consensus Cloud Solutions Inc. or its subsidiaries (collectively, &quot;Consensus&quot;)
. All rights reserved. eFax&reg; is a trademark or registered trademark of Consensus.
        </div>

        </footer>
    </div>

    <div className="banner-position-container">
    <div id="cookie-banner-container" className="cookie-banner-container">
    <div id="cookie-banner-text">
    We use cookies to ensure that we give you the best experience on our website. By continuing to use this website you are giving consent to cookies being used. To learn more visit our
    <a id="cookie-banner-policy" href="//https://www.efax.co.uk/privacy#cookie_policy">Cookie Policy &gt;&gt;</a>
    </div>
    <div id="cookie-banner-button">
    <button id="cookie-understand">I Understand </button>
    </div>
    <div className="clear-cookie"></div>
    </div>

    </div>

</div>


    </>
  )
}
export default Login